<template>
  <div>
   <el-card v-show="quoteShow">
     <template #header>
       <span style="float: left;color: #009dff;font-size: small">{{$t('msg.account.total')}}: ${{totalCost}}</span>
       <span style="float: left;color: #009dff;font-size: small;margin-left: 30px">  Total Records: {{totalItem}}</span>
       <span style="font-size: small">Batch Quotes</span>
       <el-button-group style="float: right">
         <el-button type="primary" size="small" style="margin-bottom: 40px" @click="openQuoteInfoForm">Generate Quote</el-button>
         <el-button type="primary" size="small" style="margin-bottom: 40px;margin-left: 30px" @click="addRow">Add Row</el-button>
       </el-button-group>
     </template>
     <el-table :data="drayCostDetails" style="font-size: smaller;width: 100%">
       <el-table-column fixed prop="fromArea" :label="$t('msg.dray.fromArea')" align="center" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.fromArea" size="small" Style="font-size: small" @change="handlePortChange(scope.$index)">
              <el-option v-for="item in portList" :key="item" :label="item" :value="item" />
            </el-select>
          </template>
       </el-table-column>
       <el-table-column fixed prop="ctnrid" label="DesType" align="center" width="120">
         <template #default="scope">
           <el-select v-model="scope.row.ctnrid" size="small" @change="getMyToArea(scope.row.fromArea,scope.row.ctnrid,scope.$index)">
             <el-option v-for="item in desTypeList" :key="item" :label="item" :value="item" />
           </el-select>
         </template>
       </el-table-column>
       <el-table-column fixed prop="toArea" :label="$t('msg.dray.toArea')" align="center" width="120">
          <template #default="scope">
            <el-select v-model="scope.row.toArea" size="small" :disabled="scope.row.fromArea!==curPort||scope.row.ctnrid!==curDesType">
              <el-option v-for="item in toAreaList" :key="item.name" :label="item.name" :value="item.name" />
            </el-select>
          </template>
       </el-table-column>
       <el-table-column fixed prop="ctnrType" :label="$t('msg.dray.ctnrType')" align="center" width="120">
         <template #default="scope">
           <el-select v-model="scope.row.ctnrType" size="small">
             <el-option label="20GP" value="20GP"></el-option>
             <el-option label="40GP" value="40GP"></el-option>
             <el-option label="40HQ" value="40HQ"></el-option>
             <el-option label="45HQ" value="45HQ"></el-option>
           </el-select>
         </template>
       </el-table-column>
       <el-table-column fixed prop="triAxle" label="CtnrCounts" align="center" width="140">
         <template #default="scope">
           <el-input-number v-model="scope.row.triAxle" size="small" controls-position="right" :min="1" :step="1"></el-input-number>
         </template>
       </el-table-column>
       <el-table-column prop="normTotal" :label="$t('msg.dray.normTotal')" align="center" width="130">
         <template #default="scope">
           <span >$ {{scope.row.normTotal}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="note" label="Notes" align="center" width="200"></el-table-column>
       <el-table-column prop="drayage" :label="$t('msg.dray.drayage')" align="center" width="100">
         <template #default="scope">
           <span >$ {{scope.row.drayage}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="pierPass" :label="$t('msg.dray.pierPass')" align="center" width="100">
         <template #default="scope">
           <span >$ {{scope.row.pierPass}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="chassisRent" :label="$t('msg.dray.chassisRent')" align="center" width="110">
         <template #default="scope">
           <span >$ {{scope.row.chassisRent}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="terminalFee" :label="$t('msg.dray.terminalFee')" align="center" width="110">
         <template #default="scope">
           <span >$ {{scope.row.terminalFee}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="portCongest" label="PortCongest" align="center" width="120">
         <template #default="scope">
           <span >$ {{scope.row.portCongest}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="fuelSurcharge" label="FSC" align="center" width="100">
         <template #default="scope">
           <span >$ {{scope.row.fuelSurcharge}}</span>
         </template>
       </el-table-column>
       <el-table-column prop="cleanTruck" :label="$t('msg.dray.cleanTruck')" align="center" width="120">
         <template #default="scope">
           <span >$ {{scope.row.cleanTruck}}</span>
         </template>
       </el-table-column>
       <el-table-column fixed="right" :label="$t('msg.rate.Operations')" align="center" width="200">
         <template #default="scope">
           <el-tooltip class="box-item" effect="dark" content="Get Quote" placement="top-start">
             <el-button type="success" size="small" @click="getNewQuote(scope.$index,'quoteOnly')" :icon="refreshIcon"/>
           </el-tooltip>
           <el-tooltip class="box-item" effect="dark" content="Spot RC Edit" placement="top-start">
           <el-button type="primary" size="small" @click="getNewQuote(scope.$index,'rcEdit')" :icon="toolsIcon"/>
           </el-tooltip>
           <el-button type="danger" size="small" @click="del(scope.$index)" :icon="delIcon"/>
         </template>
       </el-table-column>
     </el-table>
   </el-card>
    <el-dialog title="Quote Basic Information" v-model="quoteInfoFormShow" v-on:close="resetQuoteInfoForm()" width="30%" center draggable>
      <el-form :model="quoteInfoForm" >
        <el-form-item :label="$t('msg.rate.clientName')" prop="clientName" style="width: 350px">
          <el-select v-model="quoteInfoForm.clientName" placeholder="Select" style="width: 120px">
            <el-option v-for="item in clientList" :key="item.name" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.l3Owner')" prop="l3Owner" required style="width: 420px">
          <el-select v-model="quoteInfoForm.l3Owner" placeholder="L3 Owner">
            <el-option label="Surpath" value="Surpath"></el-option>
            <el-option label="E2E" value="E2E"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.pageRemark')" prop="pageRemark" required style="width: 350px">
          <el-input v-model="quoteInfoForm.pageRemark"></el-input>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.startDate')" prop="startDate" required >
          <el-date-picker v-model="quoteInfoForm.startDate" placeholder="Please Pick a Date" value-format="YYYYMMDD"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('msg.rate.endDate')" prop="endDate" required >
          <el-date-picker v-model="quoteInfoForm.endDate" placeholder="Please Pick a Date" value-format="YYYYMMDD"></el-date-picker>
        </el-form-item>
      </el-form>
      <el-button type="primary" v-on:click="generateQuote()" style="float: right">Submit</el-button>
    </el-dialog>
    <dray-r-c-edit :my-param="rcEditParam" @edit-complete="editComplete" v-show="editTempShow"></dray-r-c-edit>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent, onMounted, ref } from 'vue'
import { ElTooltip } from 'element-plus'
import { Delete, Refresh, Tools } from '@element-plus/icons-vue'
import axios from 'axios'
import { pdfGenerate } from '@/utils/download'

export default defineComponent({
  name: 'drayBatchQuote',
  components: {
    ElTooltip,
    drayRCEdit: defineAsyncComponent(() =>
      import('@/views/ratecard/drayRCEdit')
    )
  },
  setup() {
    const quoteShow = ref(true)
    const editTempShow = ref(false)
    const refreshIcon = Refresh
    const delIcon = Delete
    const toolsIcon = Tools
    const totalCost = ref(0)
    const totalItem = ref(0)
    const myCostDetail = ref({ ctnrid: '', ctnrType: '', fromArea: '', toArea: '', lspName: '', weight: 0, drayage: 0, pierPass: 0, chassisRent: 0, overWeight: 0, triAxle: 0, terminalFee: 0, portCongest: 0, prePull: 0, fuelSurcharge: 0, liveUnload: 0, bobTail: 0, cleanTruck: 0, normTotal: 0, note: '' })
    const drayCostDetails = ref([{ ctnrid: '', ctnrType: '', fromArea: '', toArea: '', lspName: '', weight: 0, drayage: 0, pierPass: 0, chassisRent: 0, overWeight: 0, triAxle: 0, terminalFee: 0, portCongest: 0, prePull: 0, fuelSurcharge: 0, liveUnload: 0, bobTail: 0, cleanTruck: 0, normTotal: 0, note: '' }])
    const lspSrvPortList = ref([])
    const drayRateList = ref([])
    const portList = ref([])
    const desTypeList = ref([])
    const toAreaList = ref([])
    const curPort = ref('')
    const curDesType = ref('')
    const tempCostList = ref([])
    const rcEditParam = ref('local;LGB-City')
    const quoteInfoForm = ref({ clientName: '', l3Owner: '', startDate: '', endDate: '', pageRemark: '' })
    const quoteInfoFormShow = ref(false)
    const clientList = ref([])
    const curUser = localStorage.getItem('curUser')
    const getClientList = () => {
      const param = 'curName=' + curUser
      const reqURL = process.env.VUE_APP_BASE + '/ocean/getUniqueClientName'
      axios.post(reqURL, param).then((response) => {
        clientList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const resetQuoteInfoForm = () => {
      quoteInfoForm.value = { clientName: '', oldClient: 'New Client', l3Owner: '', startDate: '', endDate: '', pageRemark: '' }
    }
    const openQuoteInfoForm = () => {
      getClientList()
      quoteInfoFormShow.value = true
    }
    const generateQuote = () => {
      console.log('generateQuote')
      const storeKeyList = getStoreKeyList(lspSrvPortList.value)
      const allExistRCList = getAllExistRC(storeKeyList)
      const param = 'lspName=' + drayCostDetails.value[0].lspName + '&allExistRCList=' + JSON.stringify(allExistRCList) +
        '&drayCostDetails=' + JSON.stringify(drayCostDetails.value) + '&clientName=' + quoteInfoForm.value.clientName + '&l3Owner=' + quoteInfoForm.value.l3Owner +
        '&startDate=' + quoteInfoForm.value.startDate + '&endDate=' + quoteInfoForm.value.endDate + '&pageRemark=' + quoteInfoForm.value.pageRemark +
        '&curUser=' + curUser
      const myUrl = '/dray/generateBatchQuote'
      const downloadName = quoteInfoForm.value.l3Owner + '-Quote.pdf'
      quoteInfoFormShow.value = false
      pdfGenerate(myUrl, param, downloadName)
    }
    const addRow = () => {
      drayCostDetails.value.push({ ctnrid: '', ctnrType: '', fromArea: '', toArea: '', lspName: '', weight: 0, drayage: 0, pierPass: 0, chassisRent: 0, overWeight: 0, triAxle: 0, terminalFee: 0, portCongest: 0, prePull: 0, fuelSurcharge: 0, liveUnload: 0, bobTail: 0, cleanTruck: 0, normTotal: 0, note: '' })
      totalItem.value = drayCostDetails.value.length
    }
    const del = (id) => {
      drayCostDetails.value.splice(id, 1)
      totalItem.value = drayCostDetails.value.length
      totalCost.value = getSum(drayCostDetails.value, 'normTotal')
    }
    const getMyToArea = (port, desType, id) => {
      curPort.value = port
      curDesType.value = desType
      const param = 'curUser=' + curUser + '&port=' + port + '&toType=' + desType
      const reqURL = process.env.VUE_APP_BASE + '/dray/getMyToAreaList'
      axios.post(reqURL, param).then((response) => {
        toAreaList.value = response.data
        drayCostDetails.value[id].toArea = toAreaList.value[0].name
      }).catch((response) => {
        console.log(response)
      })
    }
    const handlePortChange = (id) => {
      const port = drayCostDetails.value[id].fromArea
      desTypeList.value = getDesTypeList(port, lspSrvPortList.value)
      const curDesType = desTypeList.value[0]
      drayCostDetails.value[id].ctnrid = curDesType
      getMyToArea(port, curDesType, id)
    }
    const quoteValid = (id) => {
      return drayCostDetails.value[id].fromArea && drayCostDetails.value[id].ctnrid && drayCostDetails.value[id].toArea && drayCostDetails.value[id].ctnrType
    }
    const getSingleQuoteOnly = (id, myParam) => {
      const reqURL = process.env.VUE_APP_BASE + '/dray/getSingleQuoteOnly'
      axios.post(reqURL, myParam).then((response) => {
        drayCostDetails.value[id] = response.data
        totalCost.value = getSum(drayCostDetails.value, 'normTotal')
      }).catch((response) => {
        console.log(response)
      })
    }
    const goRCEdit = (storeKey) => {
      rcEditParam.value = 'local;' + storeKey + ';' + new Date().getMilliseconds()
      quoteShow.value = false
      editTempShow.value = true
    }
    const getNewQuote = (id, callType) => {
      if (!quoteValid(id)) {
        alert('Port, desType, toArea and ctnrType are all required')
        return false
      }
      const storeKey = drayCostDetails.value[id].fromArea + '-' + drayCostDetails.value[id].ctnrid
      const storedRates = localStorage.getItem(storeKey)
      const param = 'curUser=' + curUser + '&srvPort=' + drayCostDetails.value[id].fromArea + '&desType=' + drayCostDetails.value[id].ctnrid +
        '&toArea=' + drayCostDetails.value[id].toArea + '&ctnrType=' + drayCostDetails.value[id].ctnrType + '&ctnrCounts=' + drayCostDetails.value[id].triAxle
      if (storedRates) {
        if (callType === 'rcEdit') {
          goRCEdit(storeKey)
        } else {
          const myParam = param + '&drayRates=' + storedRates
          getSingleQuoteOnly(id, myParam)
        }
      } else {
        const reqURL = process.env.VUE_APP_BASE + '/dray/getSimpleQuote'
        axios.post(reqURL, param).then((response) => {
          drayCostDetails.value[id] = response.data.myCostDetail
          drayRateList.value = response.data.drayRateList
          localStorage.setItem(storeKey, JSON.stringify(drayRateList.value))
          totalCost.value = getSum(drayCostDetails.value, 'normTotal')
          if (callType === 'rcEdit') {
            goRCEdit(storeKey)
          }
        }).catch((response) => {
          console.log(response)
        })
      }
    }
    const getAffectedList = (storeTag) => {
      const srvPort = storeTag.split('-')[0]
      const desType = storeTag.split('-')[1]
      const affectList = []
      for (let n = 0; n < drayCostDetails.value.length; n++) {
        if (drayCostDetails.value[n].fromArea === srvPort && drayCostDetails.value[n].ctnrid === desType) {
          affectList.push(drayCostDetails.value[n])
        } else {
          tempCostList.value.push(drayCostDetails.value[n])
        }
      }
      return affectList
    }
    const getTransList = (list) => {
      const transList = []
      for (let n = 0; n < list.length; n++) {
        const det = list[n]
        const dropType = det.ctnrid === 'FBA' ? 'Live' : 'Drop'
        const rqstId = 'CSOP-' + det.ctnrid + dropType
        transList.push({ rqstID: rqstId, sku: 'Quoting', qty: 1, ctns: det.triAxle, ctnVol: 1, ctnWeight: 0, destination: det.toArea, shipmentID: det.ctnrid, serviceType: dropType, ctnrType: det.ctnrType, pod: det.fromArea })
      }
      return transList
    }
    const editComplete = (storeTag) => {
      console.log(storeTag)
      const affectList = getAffectedList(storeTag)
      const transList = getTransList(affectList)
      const storedRates = localStorage.getItem(storeTag)
      const param = 'lspName=' + drayCostDetails.value[0].lspName + '&transRqstList=' + JSON.stringify(transList) + '&drayRates=' + storedRates + '&tempCosts=' + JSON.stringify(tempCostList.value)
      const reqURL = process.env.VUE_APP_BASE + '/dray/getBatchQuoteForSingleCategory'
      axios.post(reqURL, param).then((response) => {
        drayCostDetails.value = response.data
        tempCostList.value = []
        totalCost.value = getSum(drayCostDetails.value, 'normTotal')
        quoteShow.value = true
        editTempShow.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      getMyToArea('LGB', 'City', 0)
      const storeKey = 'LGB-City'
      const param = 'curUser=' + curUser + '&srvPort=LGB' + '&desType=City' + '&toArea=Azusa' + '&ctnrType=40HQ' + '&ctnrCounts=1'
      const reqURL = process.env.VUE_APP_BASE + '/dray/getSimpleQuote'
      axios.post(reqURL, param).then((response) => {
        drayCostDetails.value[0] = response.data.myCostDetail
        drayRateList.value = response.data.drayRateList
        localStorage.setItem(storeKey, JSON.stringify(drayRateList.value))
        totalCost.value = getSum(drayCostDetails.value, 'normTotal')
        lspSrvPortList.value = response.data.lspSrvPortList
        portList.value = getUniquePorts(lspSrvPortList.value)
        desTypeList.value = getDesTypeList('LGB', lspSrvPortList.value)
        totalItem.value = 1
      }).catch((response) => {
        console.log(response)
      })
    })
    function getAllExistRC(storeKeyList) {
      const allExistRCList = []
      for (let n = 0; n < storeKeyList.length; n++) {
        const storeKey = storeKeyList[n]
        if (localStorage.getItem(storeKey)) {
          allExistRCList.push({ customer: storeKey, rcName: localStorage.getItem(storeKey) })
        }
      }
      return allExistRCList
    }
    function getStoreKeyList(list) {
      const storeKeyList = []
      for (let n = 0; n < list.length; n++) {
        const storeKey = list[n].name + '-' + list[n].attr
        storeKeyList.push(storeKey)
      }
      return storeKeyList
    }
    function getUniquePorts(list) {
      const portList = []
      if (list.length === 1) {
        portList.push(list[0].name)
        return portList
      } else if (list.length === 0) {
        return []
      }
      const tempList = []
      for (let n = 0; n < list.length; n++) {
        tempList.push(list[n].name)
      }
      tempList.sort()
      portList.push(tempList[0])
      for (let n = 1; n < list.length; n++) {
        if (tempList[n] !== tempList[n - 1]) {
          portList.push(tempList[n])
        }
      }
      return portList
    }
    function getDesTypeList(port, list) {
      const desTypeList = []
      for (let n = 0; n < list.length; n++) {
        if (list[n].name === port) {
          desTypeList.push(list[n].attr)
        }
      }
      return desTypeList
    }
    function getSum(list, field) {
      let totalSum = 0
      for (let n = 0; n < list.length; n++) {
        totalSum = totalSum + list[n][field]
      }
      return Math.round(totalSum)
    }
    return { curDesType, curPort, refreshIcon, quoteShow, myCostDetail, quoteInfoFormShow, clientList, editTempShow, delIcon, toolsIcon, totalCost, totalItem, drayCostDetails, drayRateList, portList, desTypeList, toAreaList, lspSrvPortList, tempCostList, rcEditParam, quoteInfoForm, getClientList, resetQuoteInfoForm, openQuoteInfoForm, editComplete, getAffectedList, handlePortChange, getNewQuote, getMyToArea, getTransList, addRow, generateQuote, del }
  }
})
</script>
